import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Competitors,
    Container,
    EarningNumber,
    ResultContainer,
    InputContainer,
    EntryContainer,
    Input,
    PlatformEarnings,
    AvgButton,
    CalcButton,
    TopContainer
} from './calculator.css';



const Calculator = ({ children, imageType, type, cover, color, title, as = '', size }) => {

    // earnings state
    const [ytEarnings, setYtEarinings] = useState(null)

    //patreon
    const [patreonEarnings, setPatreonEarnings] = useState(null)
    const [patreonPlatformEarnings, setPatreonPlatformEarnings] = useState(null)

    // chaintent
    const [chaintentEarnings, setChaintentEarnings] = useState(null)
    const [chaintentPlatformEarnings, setChaintentPlatformEarnings] = useState(null)
    const [chaintentPlatformEarningsArray, setChaintentPlatformArray] = useState(null)
    const [chaintentCreatorEarningsArray, setCreatorPlatformArray] = useState(null)


    // average or specific use 
    const [avgMode, setAvgMode] = useState(false)
    // input state
    const [unlockCount, setUnlockCount] = useState(null)
    const [unlockPrice, setUnlockPrice] = useState(null)
    const [contentCount, setContentCount] = useState(null)

    const calculateYtEarnings = (totalViews) => {

        if (totalViews < 1600) {
            setYtEarinings("$3 - $5")
            return;
        }

        if (totalViews < 5000) {
            setYtEarinings("$9 - $16")
            return;
        }

        if (totalViews < 10000) {
            setYtEarinings("$19 - $31")
            return;
        }

        if (totalViews < 20000) {
            setYtEarinings("$36 - $59")
            return;
        }

        if (totalViews < 40000) {
            setYtEarinings("$74 - $123")
            return;
        }

        if (totalViews < 70000) {
            setYtEarinings("$130 - $215")
            return;
        }

        if (totalViews < 100000) {
            setYtEarinings("$184 - $307")
            return;
        }

        if (totalViews < 125000) {
            setYtEarinings("$228 - $381")
            return;
        }

        if (totalViews < 150000) {
            setYtEarinings("$275 - $458")
            return;
        }



    }

    const withPaymentProcessorFee = price => {
        if (price < 3) {
            return (price - 0.10) * .95
        }
        if (price > 3) {
            return (price - 0.3) * 0.971
        }
    }
    const calculatePatreonEarnings = (viewCount, price, amountOfContent) => {
        console.log('yeet', viewCount, price, amountOfContent)

        const priceAfterFee = withPaymentProcessorFee(price)

        console.log('after payment processor : ', priceAfterFee)

        if (!amountOfContent || amountOfContent === null) {
            setPatreonEarnings(
                Number(
                    (priceAfterFee * 0.95) * viewCount).toFixed(2))

            setPatreonPlatformEarnings(
                Number(
                    (priceAfterFee * 0.05) * viewCount).toFixed(2))
        }
        else if (amountOfContent) {
            setPatreonEarnings(
                Number(
                    (priceAfterFee * 0.95) * viewCount * amountOfContent).toFixed(2))

            setPatreonPlatformEarnings(
                Number(
                    (priceAfterFee * 0.05) * viewCount * amountOfContent).toFixed(2))
        }
    }

    // Chaintent


    const getNumOfTiers = unlocks => {

        if (unlocks > 0 && unlocks < 999) {
            return 1
        }
        if (unlocks > 1000 && unlocks < 4999) {
            return 2
        }
        if (unlocks > 5000 && unlocks < 9999) {
            return 3
        }
        if (unlocks > 10000 && unlocks < 24999) {
            return 4
        }
        if (unlocks > 25000 && unlocks < 49999) {
            return 5
        }
        if (unlocks > 50000 && unlocks < 99999) {
            return 6
        }
        if (unlocks > 100000 && unlocks < 249999) {
            return 7
        }
        if (unlocks > 250000 && unlocks < 499999) {
            return 8
        }
        if (unlocks > 500000 && unlocks < 999999) {
            return 9
        }
        if (unlocks > 1000000 && unlocks < 4999999) {
            return 10
        }
        else {
            return 11
        }
    }

    const getRateForTier = tier => {
        switch (tier) {

            case 1:
                return {
                    rate: 0.03,
                    amtInRange: 1000
                }
            case 2:
                return {
                    rate: 0.035,
                    amtInRange: 4000
                }

            case 3:
                return {
                    rate: 0.04,
                    amtInRange: 5000
                }

            case 4:
                return {
                    rate: 0.045,
                    amtInRange: 15000
                }

            case 5:
                return {
                    rate: 0.05,
                    amtInRange: 15000
                }

            case 6:
                return {
                    rate: 0.055,
                    amtInRange: 50000
                }

            case 7:
                return {
                    rate: 0.06,
                    amtInRange: 150000
                }

            case 8:
                return {
                    rate: 0.065,
                    amtInRange: 250000
                }

            case 9:
                return {
                    rate: 0.07,
                    amtInRange: 500000
                }

            case 10:
                return {
                    rate: 0.075,
                    amtInRange: 4000000
                }

            case 11:
                return {
                    rate: 0.08,
                    amtInRange: 0
                }
        }
    }


    const calculateChaintentEarnings = (unlocks, price, amountOfContent) => {
        console.log('ye', unlocks, price, amountOfContent)
        let totalPlatformEarnings = 0;
        let totalCreatorEarnings = 0;
        let viewsInTierArray = [];
        let totalPlatformEarnedInTierArray = [];
        let totalCreatorEarnedInTierArray = [];

        unlocks = parseInt(unlocks)
        price = parseFloat(price)
        console.log(typeof unlocks, price)

        let totalUnlocksLeft = unlocks;

        const tierCount = getNumOfTiers(unlocks)


        for (var i = 1; i <= tierCount; i++) {
            let currentTier = i;
            let { rate, amtInRange } = getRateForTier(i)
            let currentTierPlatformEarnings;
            let currentTierCreatorEarnings;

            if (totalUnlocksLeft - amtInRange > 0) {

                // platform earnings
                currentTierPlatformEarnings = rate * price * amtInRange;
                totalPlatformEarnedInTierArray.push(currentTierPlatformEarnings);
                totalPlatformEarnings += currentTierPlatformEarnings;

                // creator earnings
                currentTierCreatorEarnings =
                    (1 - rate) * price * amtInRange;
                console.log('current tier creator earnings: ', currentTierCreatorEarnings)

                totalCreatorEarnedInTierArray.push(currentTierCreatorEarnings);
                totalCreatorEarnings += currentTierCreatorEarnings;


                console.log(currentTierCreatorEarnings, totalCreatorEarnings, currentTierPlatformEarnings, totalPlatformEarnings)
                // subtract unlock count from the total amount that was left
                totalUnlocksLeft = totalUnlocksLeft - amtInRange
                // check
                console.log('rate', rate, 'amount in range:',
                    amtInRange, 'total unlocks left: ', totalUnlocksLeft,
                    'creator earning are ', totalCreatorEarnings, ' and ',
                    'current total earnings for chaintent: ', totalPlatformEarnings,
                    ' in tier ', i)

            }
            else if (totalUnlocksLeft < amtInRange) {
                console.log('at last tier')
                // platform earnings
                currentTierPlatformEarnings =
                    rate * price * totalUnlocksLeft;
                totalPlatformEarnedInTierArray.push(currentTierPlatformEarnings);
                totalPlatformEarnings += currentTierPlatformEarnings;

                // creator earnings
                currentTierCreatorEarnings = (1 - rate) * price * totalUnlocksLeft;

                console.log('last tier creator earnings : ',
                    currentTierCreatorEarnings,
                    totalUnlocksLeft)


                totalCreatorEarnedInTierArray.push(currentTierCreatorEarnings);
                totalCreatorEarnings += currentTierCreatorEarnings;

                // check
                console.log('rate', rate, 'amount in range:',
                    amtInRange, 'total unlocks left: ', totalUnlocksLeft,
                    'creator earning are ', totalCreatorEarnings, ' and ',
                    'current total earnings for chaintent: ', totalPlatformEarnings,
                    ' in tier ', i)

                if (amountOfContent && amountOfContent !== null) {
                    console.log('amount of content spcified! ', amountOfContent)
                    // if amount of content is provided then this is calculating average and not
                    // a specific use case
                    let totalAvgEarningsPlatform = amountOfContent * totalPlatformEarnings
                    let totalAvgEarningsCreator = amountOfContent * totalCreatorEarnings
                    setChaintentEarnings(Number(totalAvgEarningsPlatform).toFixed(2))
                    setChaintentPlatformEarnings(Number(totalAvgEarningsPlatform).toFixed(2))
                } else {
                    setChaintentEarnings(Number(totalCreatorEarnings).toFixed(2))
                    setChaintentPlatformEarnings(Number(totalPlatformEarnings).toFixed(2))
                }



                console.log(currentTierCreatorEarnings, currentTierCreatorEarnings, currentTierPlatformEarnings, totalPlatformEarnings)
                // return {
                //     totalPlatformEarnings,
                //     totalCreatorEarnings,
                //     totalPlatformEarnedInTierArray,
                //     totalCreatorEarnedInTierArray
                // }
            }


        }


    }


    const calculateEarnings = (views, price, numOfUnlocks) => {
        let chaintent;
        let patreon;
        let youtube;



        // console.log("after processor fee: ", withPaymentProcessorFee(2.5))
        // console.log("state is currently: ", unlockCount, unlockPrice, contentCount)
        console.log(views, price, numOfUnlocks)

        calculateYtEarnings(views)
        calculatePatreonEarnings(views, price, numOfUnlocks)
        calculateChaintentEarnings(views, price, numOfUnlocks)

    }





    return (
        <Container >


            <InputContainer>

                <EntryContainer>
                    <h1>
                        {avgMode ?
                            "Avg. amount of unlocks per thread" :
                            "Views/unlocks for content"}
                    </h1>
                    <Input
                        type='number'
                        onChange={e => setUnlockCount(e.target.value)}
                        placeholder="Add # of unlocks..." />

                </EntryContainer>


                <EntryContainer>
                    <h1>
                        {avgMode ?
                            "Avg. paid for threads per month" :
                            "Specific unlock price for thread"}
                    </h1>
                    <Input
                        type='number'
                        onChange={e => setUnlockPrice(e.target.value)}
                        placeholder="Add price..." />


                </EntryContainer>

                {
                    avgMode && <EntryContainer>
                        <h1>
                            {avgMode ? "Avg. of total threads unlocked per month"
                                : "Threads unlocked"}
                        </h1>
                        <Input
                            type='number'
                            onChange={e => setContentCount(e.target.value)}
                            placeholder="# of pieces of content..." />

                    </EntryContainer>
                }

            </InputContainer>



            <ResultContainer>


                {/* 

                Youtube

                */}

                <Competitors>
                    <h1>
                        Youtube earnings
                    </h1>
                    <EarningNumber>
                        <h2>creator gets:</h2>

                        {ytEarnings !== null ? ytEarnings : 'not a lot :('}
                    </EarningNumber>

                    <br />

                    <br />

                    <EarningNumber>

                        <h2>
                            platform gets:
                        </h2>
                        ??
                    </EarningNumber>

                    <br />
                    based on calculator from Influencer Marketing Hub

                </Competitors>

                {/* 

                Chaintent

                */}

                <PlatformEarnings >
                    <h1>
                        Chaintent earnings
                    </h1>

                    <EarningNumber>
                        <h2>creator gets:</h2>
                        {
                            chaintentEarnings !== null ?
                                `$${chaintentEarnings}` :
                                'a lot :)'
                        }
                    </EarningNumber>

                    <br />

                    <br />

                    <EarningNumber>

                        <h2>
                            platform gets:
                        </h2>
                        {
                            chaintentPlatformEarnings !== null ?
                                `$${chaintentPlatformEarnings}` :
                                'a whooole lot :)'
                        }
                    </EarningNumber>

                    <br />
                    with long-tail tiered fee system
                </PlatformEarnings>

                {/* 

                Patreon

                */}

                <Competitors>

                    <h1>
                        Patreon earnings
                    </h1>

                    <EarningNumber>
                        <h2>creator gets:</h2>

                        {
                            patreonEarnings !== null ?
                                `$${patreonEarnings}` :
                                'not a lot :('
                        }
                    </EarningNumber>

                    <br />
                    <br />

                    <EarningNumber>
                        <h2>platform gets:</h2>
                        {
                            patreonPlatformEarnings !== null ?
                                `$${patreonPlatformEarnings}` :
                                'a lot :('
                        }
                    </EarningNumber>

                    <br />
                    with legacy payment processors and platform fee
                </Competitors>


            </ResultContainer>



            <TopContainer>
                <AvgButton onClick={() => setAvgMode(!avgMode)}>
                    {avgMode ? "Change to Specific Use Case"
                        : "Change to Average Calcuation"}
                </AvgButton>


                <CalcButton
                    onClick={() => {
                        setChaintentPlatformEarnings(0)
                        setChaintentEarnings(0)
                        setPatreonPlatformEarnings(0)
                        setPatreonEarnings(0)
                        calculateEarnings(unlockCount, unlockPrice, contentCount)
                    }
                    }>
                    Calculate Earnings
                </CalcButton>
            </TopContainer>


        </Container>
    );
};

export default Calculator;