import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "constants/theme"

export const Container = styled.div`
// background: lightblue;
margin-left: 2.5rem;
margin-top: 7.5rem;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
//   flex-direction: row;
margin-left: 0rem;
width: 85%

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`
  width: 82.5%

  `};
`

export const AvgButton = styled.button`
color: ${orange};
padding: 1rem;
border-radius: .33rem;
border: 2px grey;
position: static;
max-width: 25%;
margin: 1rem;
height: auto;
left: 1rem;
top: 4rem;

&:hover {
    cursor: pointer;
}

${MEDIA.PHONE`
height: 4rem;
font-size: 1rem;
max-width: 40%;
border-radius: .7rem;

  `};
`

export const CalcButton = styled(AvgButton)`
color: ${orange};
padding: 1.5rem;
border: red;
`

export const TopContainer = styled.div`
width: 100%;
height: 10%;
display: flex;
align-items: center;
justify-content: center;
// background: orange;
position: relative;



${MEDIA.PHONE`
// margin-top: 8rem;
left: 0;
width: 100%;
z-index: 10;
`};

`

export const ResultContainer = styled.div`
// background: red;
height: 25rem;
width: 100%;
left: 0;
display: flex;
align-items: center;
justify-content: center;


${MEDIA.PHONE`
width: auto;

border: 1px red;
height: 100%;
// position: absolute;
flex-direction: column;


`};
`

export const Competitors = styled.div`
height: auto;

width: auto;
padding: 1rem;
font-size: 40%;
border-radius: 1rem;
box-shadow: 1px 1px 4px 4px rgba(0,0,0,0.05);
margin: 5%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;

flex-direction: column;
max-width: 20%;
h1 {
    font-weight: 800;
    font-size: 120%;

}


${MEDIA.PHONE`
padding: .75rem;
height: 6rem;
width: 100%;
max-width: 85%;
font-size: .66rem;
flex-direction: row;
  `};

`

export const EarningNumber = styled.span`
font-size: 2.33rem;
margin: 1rem;
color: ${blue};
font-weight: 600;

h2{
    color: grey;
    font-size: 66%;
}


${MEDIA.PHONE`
font-size: 1rem;

h2 {
    font-size: 80%;
}

  `};
`

export const PlatformEarnings = styled(Competitors)`
// background: purple;
padding: 1.5rem;

`



export const InputContainer = styled.div`
// background: blue;
width: 100%;
height: auto;
display: flex;

${MEDIA.PHONE`
flex-direction: column;
height: auto;
left: 0;
position: relative;
    width: 100%;

  `};
`

export const EntryContainer = styled(Competitors)`
// background: orange;
min-width: 20%;
height: auto;
padding: 1rem;
h1 {
    font-size: 115%;
}


  ${MEDIA.PHONE`
    height: 3rem;
  `};

`

export const Input = styled.input`
// background: transparent;
height: 3rem;
margin: 1rem;
border: transparent;
background: transparent;


${MEDIA.PHONE`
width: 100%;
height: 2rem;
border: 1px ${orange};
  `};
`

